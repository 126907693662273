<template>
	<div class="info-boxes mb-6">
		<v-snackbar v-model="show" :timeout="4500" top color="black">
			Please make your browser wider to view the tutorial.
		</v-snackbar>
		<v-row class="mt-2">
			<v-col cols="12" sm="6" md="2" class="pr-2">
				<v-card class="card-item" height="93" @click="setupTutorial">
					<v-card-text class="py-3">
						<h3 class="fs--16 fw--400">Setup Tutorial</h3>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" sm="6" md="2" class="px-2">
				<v-card class="card-item" height="93" @click="setupTutorial">
					<v-card-text class="py-3">
						<h3 class="fs--16 fw--400">Activation Status</h3>
						<div v-if="isExtensionActivated">
							<span class="dot dot-online"></span>
							<span class="online pl-2">ACTIVE</span>
						</div>
						<div v-else>
							<span class="dot dot-offline"></span>
							<span class="offline pl-2">DISABLED</span>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" sm="6" md="2" class="pl-2 pr-1">
				<v-card class="card-item" height="93">
					<v-card-text class="py-3 pr-2">
						<h3 class="fs--16 fw--400">Browser Source Status</h3>
						<div v-if="data.streamerOnline">
							<span class="dot dot-online"></span>
							<span class="online pl-2">ACTIVE</span>
						</div>
						<div v-else>
							<span class="dot dot-offline"></span>
							<span class="offline pl-2">INACTIVE</span>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" sm="6" md="3">
				<v-card class="card-item" height="93">
					<v-card-text class="py-3">
						<h3 class="fs--20 fw--400">Sticker Party</h3>
						<div v-if="data.stickerPartyEnabled">
							<span class="dot dot-online"></span>
							<span class="online pl-2">ENABLED</span>
						</div>
						<div v-else>
							<span class="dot dot-offline"></span>
							<span class="offline pl-2">DISABLED</span>
						</div>
					</v-card-text>
					<img class="party-image" height="117" src="@/assets/images/backgrounds/party-dash.png"
						alt="Sticker Party" />
				</v-card>
			</v-col>
			<v-col cols="12" sm="6" md="3" v-if="data.stickerSlotAvailable">
				<v-card height="93">
					<v-card-text class="card-item pt-4" @click="actions.gotoStickersPage()">
						<v-row align="center">
							<v-col cols="8">
								<h3 class="fs--20 d-block d-md-none d-lg-block fw--400">Sticker Slot Available!</h3>
								<h3 class="fs--16 d-none d-md-block d-lg-none fw--400">Sticker Slot Available!</h3>
							</v-col>
							<v-col cols="4" class="text-right">
								<v-btn fab width="60" height="60" color="pink">
									<v-icon size="38">
										{{ icons.mdiPlus }}
									</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import axios from '@axios';
import { computed, reactive, ref } from '@vue/composition-api';
import { useRouter } from '@core/utils';
import { mdiPlusCircle, mdiPlus } from '@mdi/js';
import { getVuetify } from '@core/utils';
import store from '@/store';

export default {
	setup() {
		const $vuetify = getVuetify();
		const show = ref(false);

		const data = reactive({
			streamerOnline: false,
			stickerPartyEnabled: false,
			stickerSlotAvailable: false,
			stickerSlotTarget: null,
		});

		const isExtensionActivated = computed(() => {
			if (store.state.app.activatedExtensions) {
				for (const e of store.state.app.activatedExtensions) {
					if (e.extensions.find(ext => ext.isStreamStickers)) {
						return true;
					}
				}
			}


			return false;
		});

		const { router } = useRouter();
		const actions = {
			fetchData: function () {
				axios.get('/dashboard/info-boxes').then(res => {
					Object.assign(data, res.data);
				});
			},
			gotoStickersPage: function () {
				router.push({ name: 'streamer-stickers', query: { available: true } });
			},
		};

		actions.fetchData();

		const setupTutorial = () => {
			if ($vuetify.breakpoint.mdAndUp) {
				show.value = false;
				store.commit('app/SET_TUTORIAL', true);
			} else {
				show.value = true;
			}
		};

		return {
			show,
			data: data,
			isExtensionActivated,
			actions: actions,
			icons: {
				mdiPlusCircle,
				mdiPlus,
			},
			setupTutorial,
		};
	},
};
</script>

<style lang="scss" scoped>
$online-color: #66d544;
$offline-color: #7265b2;

.card-item {
	position: relative;
}

.party {
	border: 2px solid #4a93d9;
}

.card-item:hover {
	background-image: url('../../../assets/images/backgrounds/bk-setup-tutorial.png') !important;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right;
	transition: 0.2s ease-in-out;
	cursor: pointer;
}

.party-image {
	position: absolute;
	right: -12px;
	top: -15px;
}

.info-boxes {
	opacity: 0.85;
	width: 100%;

	.setup-tutorial {
		background-image: url('../../../assets/images/backgrounds/bk-setup-tutorial.png') !important;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: right;
	}

	.sticker-slot {
		.add-slot {
			color: #e8318e;
		}
	}

	.dot {
		height: 10px;
		width: 10px;
		border-radius: 50%;
		display: inline-block;
	}

	.dot-online {
		background-color: $online-color;
	}

	.dot-offline {
		background-color: $offline-color;
	}

	.online {
		color: $online-color;
		font-size: small;
	}

	.offline {
		color: $offline-color;
		font-size: small;
	}
}
</style>
